import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Content from "../../components/Content"
import Gallery from "../../components/Gallery"

const galImgs = ['/page-images/12.jpg']

export default function OtherServices() {
  return (
    <Layout breadcrumbs={["Other Services"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p><strong>Other Services</strong></p>
<p>	The preceding pages only give a brief overview of the principle services we offer and within those described there are many additional benefits and input provided. Further services can either be provided in-house or by arranging for you to engage outside consultants. Such services include:</p>
<ul><li>Project Management</li>
<li>Development Feasibility Studies</li>
<li>Measured Site &amp; Building Surveys</li>
<li>CAD Visualisation of Projects</li>
<li>Condition Reports &amp; Schedules for Existing Buildings</li>
        <li>Land Transfer/Lease Maps</li>
        </ul>

</Content>

      <div class="region region-content-bottom">
        <MenuOne active="what-we-do" />
        <MenuTwo active="building-control" items={[
          "../initial-designs",
          "../planning-approvals",
          "../building-control",
          "../site-operations",
          "../other-services"
        ]} />
      </div>

  </Layout>

  )
}
